import React from "react";
import styles from "./BookDemoButton.module.css";

function BookDemoButton() {
  return (
    <button
      className={styles.book_demo_button}
      onClick={() => (window.location.href = "mailto:info@lucite.ai")}
    >
      Book a demo
    </button>
  );
}

export default BookDemoButton;