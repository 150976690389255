// SecureSection.js
import React from "react";
import ImageToggle from "./ImageToggle";
import styles from "./SecureSection.module.css";

const SecureSection = () => {
  return (
    <div className={styles.secure_container}>
      <h1 className={styles.secure_title}>
        <div>Lucite is secure</div>
        <p className={styles.secure_subtitle}>
          Your data protection and privacy are paramount to us. We never train our model on user data, and our development processes are designed to constantly monitor for vulnerabilities.
        </p>
      </h1>

      <div className={styles.images_container}>
        <div className={styles.image_item}>
          <ImageToggle
            filepath="/secure_1.png"
            frontText="Data protection and privacy compliance"
            backText1="Data encryption and secure data handling practices."
            backText2="No model training on user data."
          />
        </div>

        <div className={styles.image_item}>
          <ImageToggle
            filepath="/secure_2.png"
            frontText="Secure development and deployment"
            backText1="Continuous monitoring for threats and vulnerabilities."
            backText2="Secure coding practices and integrity checks."
          />
        </div>

        <div className={styles.image_item}>
          <ImageToggle
            filepath="/secure_3.png"
            frontText="Access control and identity management"
            backText1="Role-based permissions to limit data access."
            backText2="Continuous auditing and monitoring of access points for unauthorized activity."
          />
        </div>
      </div>
    </div>
  );
};

export default SecureSection;
