import React, { useState, useEffect } from "react";
import styles from "./EfficiencySection.module.css";

const EfficiencySection = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <section className={styles.section_container}>
      <div className={styles.text_container}>
        <h1 className={styles.heading_primary}>Welcome to the age of</h1>
        <h1 className={styles.heading_secondary}>
          efficiency with <span className={styles.heading_tertiary}>Lucite</span>
        </h1>
      </div>
      <div className={styles.yc_container}>
        <h6>Backed by</h6>
        <img src="/yc-logo.png" alt="YC Logo" />
      </div>

      <div className={styles.images_container}>
        <img
          src={isMobile ? "/transform_img_1_mobile.png" : "/transform_img_1.png"}
          alt="Efficiency illustration 1"
          className={styles.section_image}
        />
        <img
          src={isMobile ? "/transform_img_2_mobile.png" : "/transform_img_2.png"}
          alt="Efficiency illustration 2"
          className={styles.section_image}
        />
      </div>

      <div className={styles.bottom_text_container}>
        <p className={styles.streamline_header_primary}>How Lucite</p>
        <p className={styles.streamline_header_secondary}>streamlines your work</p>
      </div>
    </section>
  );
};

export default EfficiencySection;
