// StreamlineComponents.js
import React from "react";
import Lottie from "lottie-react";
import styles from "./StreamlineSection.module.css";
import analysisAnimationData from "../../assets/lottie-analysis.json";
import insightsAnimationData from "../../assets/lottie-insights.json";
import outputsAnimationData from "../../assets/lottie-outputs.json";

const StreamlineBlock = ({ title, subtitle, animationData }) => {
  return (
    <div className={styles.content_block}>
      <div className={styles.content_left}>
        <p className={styles.block_title}>{title}</p>
        <p className={styles.block_subtitle}>{subtitle}</p>
      </div>
      <div className={styles.content_right}>
        <Lottie
          animationData={animationData}
          className={styles.lottie_animation}
        />
      </div>
    </div>
  );
};

const StreamlineSection = () => {
  const blocks = [
    {
      title: "Precise document analysis",
      subtitle:
        "Condense hours of work into minutes with the world's most advanced OCR and data extraction technology",
      animationData: analysisAnimationData,
    },
    {
      title: "Insights at your fingertips",
      subtitle:
        "Seamlessly search and source your documents for key data points and insights tailored specifically for benefits insurance brokers and advisors",
      animationData: insightsAnimationData,
    },
    {
      title: "Tailored, client-ready deliverables",
      subtitle:
        "Automatically generate reports formatted in your specific design standards and formatting requirements",
      animationData: outputsAnimationData,
    },
  ];
  return (
    <section className={styles.main_section}>
      <div className={styles.blocks_container}>
        {blocks.map((block, index) => (
          <StreamlineBlock
            key={index}
            title={block.title}
            subtitle={block.subtitle}
            animationData={block.animationData}
          />
        ))}
      </div>
    </section>
  );
};

export default StreamlineSection;
