import styles from './BanishSection.module.css';
import BookDemoButton from './BookDemoButton';

const BanishSection = () => {
  return (
    <section className={styles.container}>
      <picture>
        <source media="(max-width: 768px)" srcSet="/banish_img_mobile.png" />
        <img 
          src="/banish_img.png" 
          alt="Banish section" 
          className={styles.main_image}
        />
      </picture>
      <h2 className={styles.banish_heading}>Ready to banish busy work?</h2>
      <div className={styles.button_container}>
        <BookDemoButton />
      </div>
      <footer className={styles.footer}>
        <span className={styles.footer_text}>© Lucite Technology Solutions, Inc. 2024</span>
        <span className={styles.footer_text}>info@lucite.ai</span>
      </footer>
    </section>
  );
};

export default BanishSection;
