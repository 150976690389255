import React, { useState, useEffect } from "react";
import "./TeaserSection.css";
import TryLuciteButton from "../TryLuciteButton";
import LoginButton from "../LoginButton";
import Lottie from "lottie-react";
import animationData from "../../assets/lottie-lucite-letters.json";
import mobileAnimationData from "../../assets/mobile-lottie-lucite-letters.json";

const TeaserSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="teaser-section">
      <div className="content-wrapper">
        <div className="header-text">
          <img
            src="/lucite-logo-light.png"
            alt="Lucite Logo"
            className="lucite-logo-light"
          />
        </div>
        <div className="lottie-container">
          <Lottie 
            animationData={isMobile ? mobileAnimationData : animationData} 
            loop={false} 
            autoplay={true} 
          />
        </div>
        <div className="buttons-container">
          <TryLuciteButton />
          <LoginButton />
        </div>
      </div>
    </div>
  );
};

export default TeaserSection;
