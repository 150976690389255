import React from "react";
import styles from "./LoginButton.module.css";

function LoginButton() {
  return (
    <button
      className={styles.login_button}
      onClick={() => (window.location.href = "https://app.lucite.ai")}
    >
      <span>Log In</span>
    </button>
  );
}

export default LoginButton;
