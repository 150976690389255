// ImageToggle.js
import React, { useState } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { TiDelete } from "react-icons/ti";
import styles from "./ImageToggle.module.css";

const ImageToggle = ({ filepath, frontText, backText1, backText2 }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleToggle = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className={styles.container}>
      <div
        className={`${styles.image_wrapper} ${isFlipped ? styles.flipped : ""}`}
        style={{ backgroundImage: isFlipped ? "none" : `url(${filepath})` }}
      >
        <div className={styles.text_wrapper}>
          <span className={styles.text}>
            {isFlipped ? (
              <>
                <div style={{ marginBottom: "20px" }}>{backText1}</div>
                <div>{backText2}</div>
              </>
            ) : (
              frontText
            )}
          </span>
        </div>
        <button className={styles.icon_button} onClick={handleToggle}>
          {isFlipped ? (
            <TiDelete className={styles.delete_icon} />
          ) : (
            <AiFillPlusCircle className={styles.plus_icon} />
          )}
        </button>
      </div>
    </div>
  );
};

export default ImageToggle;
