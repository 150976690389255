import React from "react";
import TeaserSection from "./sections/TeaserSection/TeaserSection";
import EfficiencySection from "./sections/EfficiencySection/EfficiencySection";
import StreamlineSection from "./sections/StreamlineSection/StreamlineSection";
import SecureSection from "./sections/SecureSection/SecureSection";
import BanishSection from "./sections/BanishSection/BanishSection";

import "./App.css";

function App() {
  return (
    <div className="App">
      <TeaserSection />
      <EfficiencySection />
      <StreamlineSection />
      <SecureSection />
      <BanishSection />
    </div>
  );
}

export default App;
